import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";
import auth from "../services/auth";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const PasswordForm = ({ token, agentID }) => {
  const [loading, toggleLoading] = useState(false);
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");

  // Schema for contact form
  const schema = yup.object().shape({
    password: yup.string().min(6).required("Password is required"),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // ),
    rePassword: yup.string().min(6).required("Password is required"),
  });

  // Handle validation
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  // Handle form submit
  const onSubmit = async (data) => {
    toggleLoading(true);
    token && (data.token = token);
    agentID && (data.agentID = agentID);
    console.log(data);
    const { success, error } = await auth.resetPass(data);
    error && toast.error(error.message);

    if (success) {
      reset();
      toast.success(success.message);
      setTimeout(() => {
        token && window.location.replace("/");
      }, 3000);
    }
    toggleLoading(false);
  };

  return (
    <form className="login" onSubmit={handleSubmit(onSubmit)}>
      <Form.Input
        type="password"
        label="New Password"
        name="password"
        fullWidth={true}
        onChange={(e) => compareFields(e, true)}
        register={register}
        error={errors.password?.message}
      />
      <Form.Input
        type="password"
        label="Re-Enter New Password"
        name="rePassword"
        fullWidth={true}
        onChange={(e) => compareFields(e)}
        register={register}
        error={errors.rePassword?.message}
      />
      <Button
        text="Change password"
        className="btn btn-primary solid square px-5"
        type="submit"
        loading={loading}
      />
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default PasswordForm;
