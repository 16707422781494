import React from "react";
import { Link } from "@reach/router";
import Button from "./button";

const Card = ({
  title,
  subTitle,
  text,
  link,
  linkText,
  img,
  button,
  onClick,
}) => {
  const close = () => {};

  return (
    <div className="card">
      {img && <img src={img} className="card-img-top" alt="Brand" />}
      <Button type="btn-close" text="&#x2715;" onClick={close()} />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {subTitle && (
          <h6 className="card-subtitle mb-2 text-muted">{subTitle}</h6>
        )}
        <p className="card-text">{text}</p>
        {link && <Link to={link}>{linkText}</Link>}
        {button && (
          <Button type="btn-primary" text={button} onClick={onClick} />
        )}
      </div>
    </div>
  );
};

const CardInline = ({
  title,
  subTitle,
  text,
  link,
  linkText,
  img,
  button,
  hide,
  onClick,
  ...rest
}) => (
  <div className="card" {...rest}>
    {img && <img src={img} className="card-img-top" alt="Brand" />}
    <Button type="btn-close" text="&#x2715;" onClick={hide} />
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      {subTitle && (
        <h6 className="card-subtitle mb-2 text-muted">{subTitle}</h6>
      )}
      <div className="inline-wrapper">
        <p className="card-text">{text}</p>
        {link && <Link to={link}>{linkText}</Link>}
        {button && (
          <Button type="btn-primary" text={button} onClick={onClick} />
        )}
      </div>
    </div>
  </div>
);

export { Card, CardInline };
