import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import session from "../services/session";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";
import auth from "../services/auth";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const LoginForm = () => {
  // Schema for contact form
  const schema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().min(6).required("Password is required"),
  });

  // Handle validation
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle form submit
  const onSubmit = async (data) => {
    console.log(data);
    const { success, error } = await auth.login(data);

    error && toast.error(error.message);
    if (success) {
      session.set("token", success.token);

      // Continue
      let redirect = "";
      if (success.brand === null) {
        redirect = "control-panel/consent/";
      } else redirect = `agent/${success.agentID}`;
      auth.check4previous(redirect);
    }
  };

  return (
    <form className="login" onSubmit={handleSubmit(onSubmit)}>
      <Form.Input
        label="Username"
        name="username"
        fullWidth={true}
        register={register}
        error={errors.username?.message}
      />
      <Form.Input
        type="password"
        label="Password"
        name="password"
        fullWidth={true}
        register={register}
        error={errors.password?.message}
      />
      <Button
        text="Login"
        className="btn btn-primary solid square px-5"
        type="submit"
      />
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default LoginForm;
