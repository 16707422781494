/* eslint-disable import/no-anonymous-default-export */
import http from "./http";
import { postAPI } from "./post";
import session from "./session";

const tokenKey = "token";

// Set headers and token
getJwt();
/**--------------------------------
 ** POST to Login
 * --------------------------------
 * Attempts to login with
 *  credentials
 *
 * @param {string} values
 * @returns {json} data
 */
export async function login(values) {
  const { data } = await postAPI(`users/login`, values);
  !data.success && !data.error && console.error(data);
  return data;
}

/**--------------------------------
 ** Continue previous session
 * --------------------------------
 * Check for previous session &
 *  return user to that page
 */
export const check4previous = (path) => {
  try {
    // Set persistent token
    let continueSession = session.get("continue");

    if (continueSession) {
      let exp = new Date(continueSession.exp);
      const now = new Date();
      exp = exp.setHours(exp.getHours() + 3);
      // If not expired use previous link
      if (exp < now) session.remove("continue");
      else {
        window.location = continueSession.url;
      }
    } else window.location.href = path;
  } catch (error) {
    console.error(error);
  }
};

/**--------------------------------
 ** POST to Request new pass
 * --------------------------------
 * Attempts to login with
 *  credentials
 *
 * @param {string} values
 * @returns {json} data
 */
export async function forgot(values) {
  const { data } = await postAPI(`users/forgot`, values);
  !data.success && !data.error && console.error(data);
  return data;
}

/**--------------------------------
 ** POST to Verify user
 * --------------------------------
 * Attempts to login with
 *  credentials
 *
 * @param {string} values
 * @returns {json} data
 */
export async function verify(values) {
  const { data } = await postAPI(`users/verify`, values);
  !data.success && !data.error && console.error(data);
  return data;
}

/**--------------------------------
 ** POST to change password
 * --------------------------------
 * Attempts to login with
 *  credentials
 *
 * @param {string} values
 * @returns {json} data
 */
export async function resetPass(values) {
  const { data } = await postAPI(`users/set-password`, values);
  !data.success && !data.error && console.error(data);
  return data;
}

/**--------------------------------
 ** Require login
 * --------------------------------
 * Redirect to login page add session
 *  to redirect url after successful login
 */
export async function requireLogin() {
  try {
    const continueSession = {
      exp: new Date(),
      url: window.location.pathname,
    };
    session.set("continue", continueSession);
    window.location.href = "/";
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------
 ** Logout
 * --------------------------------
 * Removes token, affiliates and
 *  user data from storage and
 *  redirects to root.
 */
export async function logout(inactive = null) {
  try {
    session.remove(tokenKey);

    if (inactive) {
      // Set continue values
      const continueSession = {
        exp: new Date(),
        url: window.location.pathname,
      };
      session.set("inactive", "You have been logged out due to inactivity");
      session.set("continue", continueSession);
    } else session.remove("continue");
    window.location.href = "/";
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------
 ** Check for Expired Token
 * --------------------------------
 * Checks object for errors and
 *  if starts with Expires logout
 *
 * @param {object} data
 * @returns {string}
 */
export function isExpired(data) {
  try {
    if (typeof data.errors !== "undefined") {
      data.errors.startsWith("Expired") && logout();
    }
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------
 ** Remove Token
 * --------------------------------
 */
export function reset() {
  try {
    session.remove(tokenKey);
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------
 ** Get Token
 * --------------------------------
 * Gets token from localStorage
 *  if exists.
 * - Sets token from jwt.js if not
 * @returns {string}
 */
export async function getJwt(clear = null) {
  try {
    let token = session.get(tokenKey) || false;
    if (clear || !token) {
      const { data } = await http.get("get/token.php");
      if (data.token) {
        session.set(tokenKey, data.token);
        token = data.token;
      } else {
        console.log(data);
      }
    }
    http.setJwt(token);
  } catch (error) {
    console.log(error);
  }
}

export default {
  login,
  requireLogin,
  check4previous,
  forgot,
  verify,
  resetPass,
  logout,
  reset,
  isExpired,
  getJwt,
};
