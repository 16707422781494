import { toast } from "react-toastify";

/** ------------------------------------
 ** Format phone number
 * ------------------------------------
 * @param n phone number
 * @return formatted number
 */
export function formatPhoneNum(n) {
  try {
    if (!n) return n;

    // only allows 0-9 inputs
    n = n.replace(/[^\d]/g, "");

    if (n.length < 4) return n;

    if (n.length < 7) return `${n.slice(0, 3)}-${n.slice(3)}`;

    return `${n.slice(0, 3)}-${n.slice(3, 6)}-${n.slice(6, 10)}`;
  } catch (error) {
    console.log(error);
  }
}

/** -------------------------------
 ** Mask and format zip code value
 * --------------------------------
 * @param {string} n value to be converted
 * @param {integer} max limit the length - default 5
 */
export function formatZipCode(n, max = null) {
  try {
    if (!n) return n;
    // Max limiter
    !max && (max = 5);
    // only allows 0-9 inputs
    n = n.replace(/[^\d]/g, "");
    // Prevent more than 8
    n = n.substring(0, max);
    if (n.length <= 5) return n;
    return n.slice(0, 5) + "-" + n.slice(5);
  } catch (error) {
    console.log(error);
  }
}

/** ---------------------------
 ** Update page title
 * ----------------------------
 * Gets location pathname
 *  cleans & formats it.
 * Appends site title to it.
 */
export function updateTitle(title = false) {
  try {
    if (!title) {
      const site = process.env.REACT_APP_TITLE;
      let page = window.location.pathname;
      page = page.split("/");
      page = page[page.length - 1];
      page = page.replace("/", "").replace("-", " ");
      document.title = capitalize(page) + " | " + site;
    } else {
      document.title = title;
    }
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Capitalize 1st letter of each word
 * ------------------------------------
 * @param string word or phrase
 * @return string formatted word or phrase
 */
export function capitalize(string) {
  try {
    const transform = (string) => {
      return string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };
    return transform(string);
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Smooth scroll to top of page
 * ------------------------------------
 */
export function scrollToTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

/** ------------------------------------
 ** Copy value to clipboard
 * ------------------------------------
 * @param string word or phrase
 * @return toast alert
 */
export function copyReceipt(value) {
  navigator.clipboard.writeText(value).then(
    () => {
      console.log(value);
      toast.info("Added to clipboard!");
    },
    () => {
      console.log("failed");
    }
  );
}

/** ------------------------------------
 ** Convert object to POSTable values
 * ------------------------------------
 * @param object values
 * @return URLSearchParams POST format
 */
export function postify(values) {
  try {
    let params = new URLSearchParams();
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      params.append(key, value);
    });
    return params;
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Search through array of objects
 * ------------------------------------
 * @param {object} array
 * @param {string} search
 * @return object
 */
export function searchFilter(array, search, params) {
  if (array.length !== 0) {
    if (params && search) {
      //* Check for type of filter
      var includes = search.startsWith("includes:");
      var excludes = search.startsWith("excludes:");
      (includes || excludes) && (search = search.substring(9));
    }
    return array.filter((obj) => {
      // if (search) {
      let searched = [];
      //* Search specific field
      if (params) {
        Object.entries(obj).forEach((value) => {
          var v = value;
          if (typeof params === "object") {
            params.forEach((param) => {
              if (v[0] === param) {
                value = v[1];
                if (value !== undefined && value !== null) {
                  if (includes) {
                    searched.push(value.includes(search));
                  } else if (excludes) {
                    searched.push(!value.includes(search));
                  } else searched.push(value.includes(search));
                } else searched.push(false);
              }
            });
          } else {
            if (value[0] === params) {
              value = value[1];
              if (value !== undefined && value !== null) {
                if (includes) {
                  searched.push(value === search);
                } else if (excludes) {
                  searched.push(value !== search);
                } else searched.push(value.includes(search));
              } else {
                typeof search === "object"
                  ? searched.push(typeof value === typeof search)
                  : searched.push(false);
              }
            }
          }
        }); //* Search all fields
      } else
        Object.values(obj).forEach((value) => {
          if (value !== undefined && value !== null) {
            typeof value !== "number"
              ? (value = value.toLowerCase())
              : (value = value.toString());
            searched.push(value.includes(search.toLowerCase()));
          } else searched.push(false);
        });
      return excludes
        ? !searched.includes(false) && obj
        : searched.find((v) => v === true) && obj;
    });
  } else return [];
}
