import React, { useState, useContext } from "react";
import { UserContext } from "../../context/user";
import Button from "../common/button";
import EditUsers from "../../forms/editUsers";

const UserTable = ({ v, handleDelete, handleEdit }) => {
  const { user } = useContext(UserContext);

  const [expand, toggleExpand] = useState(false);
  const [edit, toggleEdit] = useState(false);
  const [confirmDelete, showDelete] = useState(false);

  const handleToggle = () => {
    expand ? toggleExpand(false) : toggleExpand(true);
  };

  return (
    <div className={"lead-container" + (expand ? "" : " small")}>
      <div className="container lead-header">
        {expand && (
          <>
            <div className="row mb-3">
              <div className="col-sm-3">
                <h6 className="text-muted">Agent ID #</h6>
                <h5 className="confirmation">{v.agentId}</h5>
              </div>
              <div className="col-sm-2">
                <h6 className="text-muted">Role</h6>
                <h5>{v.role}</h5>
              </div>
              {(user.role === "admin" || user.brand === null) && (
                <div className="col-sm-2">
                  <h6 className="text-muted">Brand</h6>
                  <h5>{v.brand}</h5>
                </div>
              )}
              <div className="col text-right">
                <h6 className="text-muted">Last Updated</h6>
                <h5>{v.updated}</h5>
              </div>
            </div>
            <hr />
          </>
        )}
        <div className="row">
          <div className={expand ? "col-sm-3" : "col-sm-9"}>
            <h6 className="text-muted">Full name</h6>
            <h5>{v.fName + " " + v.lName}</h5>
          </div>

          {expand && (
            <>
              <div className="col-sm-5">
                <h6 className="text-muted">Email</h6>
                <h5>{v.email}</h5>
              </div>
              <div className="col-sm-3">
                <h6 className="text-muted">Phone</h6>
                <h5>{v.phone}</h5>
              </div>
            </>
          )}
          <div
            className={
              (expand ? "col-sm-1" : "col-sm-3") + " text-right mt-3 expandable"
            }
          >
            <Button
              text="&#x276F;"
              type={expand ? "btn-expanded" : "btn-collapse"}
              onClick={(e) => handleToggle()}
            />
          </div>
        </div>
      </div>
      {expand && (
        <div className="container lead-collapse">
          <hr />
          {edit && <EditUsers user={v} auth={user} handleEdit={handleEdit} />}
          <div className="flex end">
            {!confirmDelete && (
              <Button
                text="Edit"
                type="btn-warning px-4"
                onClick={() => toggleEdit(!edit)}
              />
            )}
            {confirmDelete ? (
              <>
                <h6 className="muted my-0">
                  Are you sure you want delete this user?
                </h6>
                <Button
                  text="Yes"
                  type="btn-danger ml-2"
                  onClick={() => handleDelete(v.agentId)}
                />
                <Button
                  text="No"
                  type="btn-success ml-2"
                  onClick={() => showDelete(false)}
                />
              </>
            ) : (
              <Button
                text="Delete"
                type="btn-danger ml-2"
                onClick={() => showDelete(true)}
              />
            )}
          </div>
        </div>
      )}
    </div>
    //   <div className="lead-container">
    //   <div className="container lead-header">
    //     <div className="row mb-3">
    //       <div className="col-sm-3">
    //         <h6 className="text-muted">Agent ID #</h6>
    //         <h5 className="confirmation">{v.agentId}</h5>
    //       </div>
    //       <div className="col-sm-2">
    //         <h6 className="text-muted">Role</h6>
    //         <h5>{v.role}</h5>
    //       </div>
    //       {(user.role === "admin" || user.brand === null) && (
    //         <div className="col-sm-2">
    //           <h6 className="text-muted">Brand</h6>
    //           <h5>{v.brand}</h5>
    //         </div>
    //       )}
    //       <div className="col text-right">
    //         <h6 className="text-muted">Last Updated</h6>
    //         <h5>{v.updated}</h5>
    //       </div>
    //     </div>
    //     <hr />
    //     <div className="row">
    //       <div className="col-sm-3">
    //         <h6 className="text-muted">Full name</h6>
    //         <h5>{v.fName + " " + v.lName}</h5>
    //       </div>

    //       <div className="col-sm-5">
    //         <h6 className="text-muted">Email</h6>
    //         <h5>{v.email}</h5>
    //       </div>
    //       <div className="col-sm-3">
    //         <h6 className="text-muted">Phone</h6>
    //         <h5>{v.phone}</h5>
    //       </div>
    //       <div className="col-sm-1 text-right mt-3 expandable">
    //         <Button
    //           text="&#x276F;"
    //           type={expand ? "btn-expanded" : "btn-collapse"}
    //           onClick={(e) => handleToggle()}
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   {expand && (
    //     <div className="container lead-collapse">
    //       <hr />
    //       {edit && <EditUsers user={v} auth={user} handleEdit={handleEdit} />}
    //       <div className="flex end">
    //         {!confirmDelete && (
    //           <Button
    //             text="Edit"
    //             type="btn-warning px-4"
    //             onClick={() => toggleEdit(!edit)}
    //           />
    //         )}
    //         {confirmDelete ? (
    //           <>
    //             <h6 className="muted my-0">
    //               Are you sure you want delete this user?
    //             </h6>
    //             <Button
    //               text="Yes"
    //               type="btn-danger ml-2"
    //               onClick={() => handleDelete(v.agentId)}
    //             />
    //             <Button
    //               text="No"
    //               type="btn-success ml-2"
    //               onClick={() => showDelete(false)}
    //             />
    //           </>
    //         ) : (
    //           <Button
    //             text="Delete"
    //             type="btn-danger ml-2"
    //             onClick={() => showDelete(true)}
    //           />
    //         )}
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default UserTable;
