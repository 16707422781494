import http from "./http";
import { getJwt } from "./auth";
import { toast } from "react-toastify";

/**--------------------------------------
 ** Build GET Api string
 * --------------------------------------
 * @param {string} category
 * @returns {object}
 */
export const getAPI = async (path, values) => {
  if (values) {
    return getJwt()
      .then(() => http.get(`get/${path}.php`, { params: values }))
      .catch((e) => {
        if (e.response.data.error.message === "Token is not valid!")
          window.location.reload();
        else {
          console.log(e.response);
          toast.error("Something went wrong!");
          return { data: { error: e } };
        }
      });
  } else
    return getJwt()
      .then(() => http.get(`get/${path}.php`))
      .catch((e) => {
        if (e.response.data.error.message === "Token is not valid!")
          window.location.reload();
        else {
          console.log(e.response);
          toast.error("Something went wrong!");
          return { data: { error: e } };
        }
      });
};

/**--------------------------------------
 ** Return all leads
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const getLeads = async (values) => {
  const { data } = await getAPI("leads/all", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Return all consent forms
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const getConsent = async (values) => {
  const { data } = await getAPI("consent/all", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Export Leads data
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const getLeadsExport = async (values) => {
  const { data } = await getAPI("export/leads", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Export Consent data
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const getConsentExport = async (values) => {
  const { data } = await getAPI("export/consent", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Get all users
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const getUsers = async (values) => {
  const { data } = await getAPI("users/all", values);
  !data.success && !data.error && console.log(data);
  return data;
};
