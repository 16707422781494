import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { updateTitle, scrollToTop } from "../services/utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";
import { CardInline } from "../components/common/cards";
import { submitConsent } from "../services/post";
import { Nav } from "../components/nav";
// Zipcode list
import zipcodes from "../assets/zipcodes/all.json";
// Media
import nrgLogo from "../assets/images/nrg-logo-mark.svg";
import reliantLogo from "../assets/images/reliant-logo.png";

/** -------------------------------
 *# Generic Customer Consent Form
 * --------------------------------
 */
const ReliantConsentES = (props) => {
  // Update title
  updateTitle("Customer Consent");

  const { customer_id, agent_id } = props;

  // Set state
  const [showTimes, toggleTimes] = useState(false);
  const [notServiceable, toggleServiceable] = useState(false);
  const [selectedTime, changeTimes] = useState("");
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");
  const [clear, setClear] = useState("false");
  const [show, toggleShow] = useState(true);
  const [loading, toggleLoading] = useState(false);

  // Schema for contact form
  const schema = yup.lazy((value) => {
    if (value.zip === "" || value.phone === "") {
      scrollToTop();
    }
    if (value !== "undefined") {
      return yup.object().shape({
        fName: yup.string().required("Required"),
        lName: yup.string().required("Required"),
        address: yup.string().required("Required"),
        city: yup.string().required("Required"),
        // closingDate: yup.string().required("Required"),
        brokerName: yup.string().required("Required"),
        timePreference: yup.string().required("Required"),
        timeSelection: yup.string().required("Please select a time"),
        consentReliant: yup
          .string()
          .required("Required")
          .matches(
            "yes",
            "Thank you for visiting this site.  Your information has not been collected."
          ),
        // consentNRG: yup
        //   .string()
        //   .required("Required")
        //   .matches("yes", "You must agree to submit consent form"),
        email: yup.string().email("Email not valid").required("Required"),
        reEmail: yup.string().required("Required"),
        phone: yup.string().min(12).max(12).required(""),
        zip: yup.string().min(5).required("Required"),
        message: yup.string().max(1000),
      });
    }
    return yup.mixed().notRequired();
  });

  // Handle validation
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  // Form variables
  const states = [
    { id: "IL", name: "Illinois" },
    { id: "MA", name: "Massachusetts" },
    { id: "MD", name: "Maryland" },
    { id: "NJ", name: "New Jersey" },
    { id: "OH", name: "Ohio" },
    { id: "PA", name: "Pennsylvania" },
    { id: "TX", name: "Texas" },
  ];

  const languages = [
    { id: "EN", name: "English" },
    { id: "ES", name: "Español" },
  ];

  const times = [
    { id: "", name: "Seleccionar el mejor horario" },
    { id: "morning", name: "Mañana " },
    { id: "afternoon", name: "Tarde " },
    { id: "evening", name: "Noche" },
  ];

  const selectableTimes = {
    morning: [
      { name: "timeSelection", id: "t9", value: "9am" },
      { name: "timeSelection", id: "t10", value: "10am" },
      { name: "timeSelection", id: "t11", value: "11am" },
    ],
    afternoon: [
      { name: "timeSelection", id: "t12", value: "12pm" },
      { name: "timeSelection", id: "t1", value: "1pm" },
      { name: "timeSelection", id: "t2", value: "2pm" },
      { name: "timeSelection", id: "t3", value: "3pm" },
    ],
    evening: [{ name: "timeSelection", id: "t4", value: "4pm" }],
  };

  const consent = {
    Reliant: [
      {
        name: "consentReliant",
        id: "consentYes",
        value: "yes",
        label:
          "Sí, acepto que Reliant se comunique conmigo para informarme sobre productos y servicios de energía y otros servicios para el hogar que Reliant pueda ofrecer a través de sus afiliadas o terceros, según se describe anteriormente.",
      },
      {
        name: "consentReliant",
        id: "consentNo",
        value: "no",
        label: "No, no deseo que Reliant se comunique conmigo.",
      },
    ],
    NRG: [
      {
        name: "consentNRG",
        id: "consentYesNRG",
        value: "yes",
        label:
          "Yes, I agree that Reliant Energy Retail Services, LLC ('NRG Home') may contact me",
      },
      {
        name: "consentNRG",
        id: "consentNoNRG",
        value: "no",
        label: "No, I do not wish to be contacted by NRG Home",
      },
    ],
  };

  // Display/hide time slots
  const selectTime = (e) => {
    toggleTimes(true);
    const selected = e.target.value;
    if (selected !== "") {
      changeTimes(selectableTimes[e.target.value]);
    } else {
      toggleTimes(false);
      changeTimes("");
    }
  };

  // Check for qualified zip
  const validateZip = (zip) => {
    // Check once code has been completely entered
    if (zip.length === 5) {
      zipcodes.includes(zip)
        ? toggleServiceable(false)
        : toggleServiceable(true);
    } else {
      toggleServiceable(false);
    }
    return zip;
  };

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  // Handle form submit
  const onSubmit = async (data) => {
    // if (!notServiceable) {
    toggleLoading(true);
    // Update and remove time values
    data.timePreference = data.timeSelection;
    if (customer_id) data.customer_id = customer_id;
    if (agent_id) data.agent_id = agent_id;

    delete data.timeSelection;
    delete data.reEmail;

    const { success, error } = await submitConsent(data);
    if (success) {
      console.log(success);
      scrollToTop();
      resetForm();
      toast.success(success.message, {
				position: "bottom-right",
			});
    } else {
      scrollToTop();
      error && console.log(error);
      toast.error(error);
    }
    toggleLoading(false);
    // }
  };

  // Initialize form
  const resetForm = () => {
    setClear("true");
    reset();
    setClear("false");
    toggleTimes(false);
  };

  return (
    <>
      <div className="bg-white">
        <Nav id={customer_id} />
        {/* {customer_id && show && (
					<div className='mini section-reliant'>
						<CardInline
							id='cid'
							title='Customer ID:'
							text={customer_id}
							hide={() => toggleShow(false)}
						/>
					</div>
				)} */}
        <div className="form effra section-reliant">
          <div className="title-block">
            <h2 className="sub-title">Llena el formulario para comunicarte</h2>
            <img src={reliantLogo} alt="Reliant logo" />
          </div>
          <div className="container">
            <form className="form contact-us" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <small className="header">Información del consumidor</small>
                <hr />
              </div>
              <div className="row">
                <Form.Input
                  label="Nombre del consumidor"
                  name="fName"
                  placeholder="Ingresar nombre"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.fName?.message}
                />
                <Form.Input
                  label="Apellido del consumidor"
                  name="lName"
                  placeholder="Ingresar apellido"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.lName?.message}
                />
              </div>
              <div className="row">
                <small className="header">
                  Información de contacto del consumidor
                </small>
                <hr />
              </div>
              <div className="row spaced-out">
                <Form.Input
                  label="Dirección"
                  name="address"
                  placeholder="Ingresar dirección"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.address?.message}
                />
                <Form.Input
                  label="Ciudad"
                  name="city"
                  placeholder="Ingresar ciudad"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.city?.message}
                />
                <Form.Select
                  label="Estado "
                  name="state"
                  values={states}
                  selected="TX"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.state?.message}
                />
                <Controller
                  name="zip"
                  defaultValue=""
                  control={control}
                  render={(props) => (
                    <Form.Input
                      type="zipcode"
                      label="Código postal "
                      name="zip"
                      placeholder="54123"
                      onChange={(e) => props.onChange(e)}
                      required={true}
                      clear={clear}
                      error={errors.zip?.message}
                    />
                  )}
                />
              </div>
              {notServiceable && (
                <div id="serviceable">
                  <p>The provided zipcode is not currently serviceable.</p>
                </div>
              )}
              <div className="row spaced-out">
                <Controller
                  name="phone"
                  defaultValue=""
                  control={control}
                  render={(props) => (
                    <Form.Input
                      type="phone"
                      label="Teléfono"
                      required={true}
                      name="phone"
                      placeholder="___ - ___ - ____"
                      onChange={(e) => props.onChange(e)}
                      clear={clear}
                      error={errors.phone?.message}
                    />
                  )}
                />
                <Controller
                  name="altPhone"
                  defaultValue=""
                  control={control}
                  render={(props) => (
                    <Form.Input
                      type="phone"
                      label="Teléfono alternativo"
                      name="altPhone"
                      placeholder="___ - ___ - ____"
                      onChange={(e) => props.onChange(e)}
                      clear={clear}
                      register={register}
                      error={errors.altPhone?.message}
                    />
                  )}
                />
                <Form.Input
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Ingresar email"
                  required={true}
                  onChange={(e) => compareFields(e, true)}
                  clear={clear}
                  register={register}
                  error={errors.email?.message}
                />
                <Form.Input
                  type="email"
                  label="Reingresar email"
                  name="reEmail"
                  placeholder="Ingresar email"
                  required={true}
                  onChange={(e) => compareFields(e)}
                  clear={clear}
                  register={register}
                  error={errors.reEmail?.message}
                />
              </div>
              <div className="row">
                <small className="header">Preferencias del consumidor</small>
                <hr />
              </div>
              <div className="row">
                <Form.Select
                  label="Idioma preferido"
                  name="lang"
                  values={languages}
                  clear={clear}
                  register={register}
                  error={errors.lang?.message}
                />
                <Form.Select
                  label="Cita"
                  name="timePreference"
                  values={times}
                  required={true}
                  clear={clear}
                  onChange={(e) => selectTime(e)}
                  register={register}
                  error={errors.timePreference?.message}
                />
                {showTimes && (
                  <Form.RadioInline
                    label="Seleccionar el mejor horario"
                    values={selectedTime}
                    required={true}
                    clear={clear}
                    register={register}
                    error={errors.timeSelection?.message}
                  />
                )}
                {/* <Form.Input
									type='date'
									label='Closing date'
									name='closingDate'
									required={true}
									clear={clear}
									register={register}
									error={errors.closingDate?.message}
								/> */}
              </div>
              <div className="row">
                <Form.Textarea
                  label="Comentarios"
                  name="comments"
                  placeholder="Escriba aquí cualquier información adicional"
                  // small="Note to agent: NRG will contact customer 2 weeks prior to closing date"
                  fullWidth={true}
                  cols="30"
                  rows="5"
                  clear={clear}
                  register={register}
                />
              </div>
              <div className="row">
                <small className="header">Información del agente</small>
                <hr />
              </div>
              <div className="row">
                <Form.Input
                  label="Oficina / Agente"
                  name="brokerName"
                  placeholder="Ingresar nombre"
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.brokerName?.message}
                />
              </div>
              <div className="row">
                <p className="smaller-text">
                  {/* By clicking “Submit” you represent that the contact information
                submitted belongs to you and that you hereby authorized Reliant
                Energy Retail Services, LLC (“Reliant”) to contact you for
                marketing purposes at the telephone number, email address, or
                mobile number you provide on this form and that you authorize
                Reliant or NRG Home to use automated telephone technology
                including auto-dialers and text messages, even if your telephone
                or mobile number is currently listed on any federal, state, or
                corporate “Do Not Call” list. You are not required to give your
                consent as a condition of purchase. */}
                  Al hacer clic en <strong>"Enviar"</strong>, acepta que Reliant
                  Energy Retail Services, LLC ("Reliant") pueda comunicarse con
                  usted acerca de productos y servicios de energía, así como
                  otros servicios para el hogar que Reliant pueda ofrecer a
                  través de sus afiliadas o terceros, y que fue usted quien
                  proporcionó el número telefónico, de móvil o dirección de
                  email que aparecen en este formulario, que le pertenecen y que
                  están correctos. Además, autoriza a Reliant a comunicarse con
                  usted con fines de mercadeo utilizando tecnología telefónica
                  automática que incluye marcadores automáticos y mensajes de
                  texto al número telefónico, de móvil o dirección de email que
                  proporcionó en este formulario, incluso si dicho número
                  telefónico o de móvil se encuentra actualmente en alguna lista
                  federal o estatal, o en la lista corporativa de “No llamar” de
                  Reliant. No se requiere que dé su consentimiento como
                  condición para la compra.{" "}
                </p>
              </div>
              <div className="row">
                <Form.RadioInline
                  label="Reliant Consent"
                  values={consent.Reliant}
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.consentReliant?.message}
                />
                {/* <Form.RadioInline
                label="NRG Consent"
                values={consent.NRG}
                required={true}
                clear={clear}
                register={register}
                error={errors.consentNRG?.message}
              /> */}
              </div>
              <div className="row">
                <hr />
              </div>
              <Button
                text="Enviar"
                className="btn btn-success pill-button"
                type="submit"
                loading={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ReliantConsentES;
