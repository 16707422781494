import React from "react";
import Consent from "../components/control-panels/consent";
import Leads from "../components/control-panels/leads";
import Users from "../components/control-panels/users";
import NotFound from "./404";

const ControlPanel = (props) => {
  switch (props.type) {
    case "consent":
      return <Consent id={props.id} />;
    case "leads":
      return <Leads id={props.id} />;
    case "users":
      return <Users id={props.id} />;
    default:
      return <NotFound default />;
  }
};

export default ControlPanel;
