import React from "react";
import loadImg from "../../assets/images/loading.gif";

const button = ({ text, type, loading, children, ...rest }) => (
  <>
    {loading ? (
      <img className="loading" src={loadImg} alt="Loading" />
    ) : children ? (
      <button className={"btn " + type} {...rest}>
        {children}
      </button>
    ) : (
      <button className={"btn " + type} {...rest}>
        {text}
      </button>
    )}
  </>
);

export default button;
