import React from "react";
import { Link, Match, useLocation } from "@reach/router";

export const NavLink = (props) => {
  const location = useLocation();

  return (
    <Match path={props.to}>
      {({ match }) => (
        <div
          className={
            props.className +
            (props.override
              ? props.override
              : " nav-link" + (match ? " active" : ""))
          }
        >
          <Link to={props.to} state={{ prevPath: location.pathname }}>
            {props.children}
          </Link>
        </div>
      )}
    </Match>
  );
};
