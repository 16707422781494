import React from "react";
import { Link } from "@reach/router";

const NotFound = () => (
  <section className="mt-5">
    <div className="container text-center p-5">
      <h1 className="display-1">404</h1>
      <h1>Page not found</h1>
      <h2 className="mt-5">
        <Link to="/">Start over</Link>
      </h2>
    </div>
  </section>
);

export default NotFound;
