import React, { useState, useEffect } from "react";
import loadImg from "../../assets/images/loading.gif";
import test from "../../assets/images/nrg-logo-mark.svg";

const Loading = (props) => {
  const [loading, toggleLoading] = useState(true);
  const [fade, toggleFade] = useState(false);

  useEffect(() => {
    // console.log(props.loading);
    if (props.loading) {
      toggleLoading(true);
      // toggleFade(true);
    } else {
      toggleFade(true);
      setTimeout(() => {
        toggleLoading(false);
      }, 700);
    }
  }, [props.loading]);

  return (
    <>
      {loading ? (
        <section className={"mini loading" + (fade ? " fade-out" : "")}>
          <h4>{props.message}</h4>
          <img className="loading" src={loadImg} alt="Loading" />
        </section>
      ) : (
        props.children
      )}
    </>
  );
};

export default Loading;
