import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/user";
import { NavLink } from "./common/navlink";
import Burger from "@animated-burgers/burger-squeeze";
import "@animated-burgers/burger-squeeze/dist/styles.css";
// media
import mzLogo from "../assets/images/mz.png";
import nrgLogo from "../assets/images/nrg-logo-mark.svg";
import Button from "./common/button";
import icon from "../assets/images/profile.png";

const Nav = ({ id }) => {
	const { user } = useContext(UserContext);

	useWindowSize();
	const [expand, toggleExpand] = useState(false);
	const [collapse, toggleCollapse] = useState(false);
	const [showMenu, toggleMenu] = useState(false);

	function useWindowSize() {
		useEffect(() => {
			function handleResize() {
				const breakpoint = user.role === "admin" ? 960 : 820;
				window.innerWidth < breakpoint
					? toggleCollapse(true)
					: toggleCollapse(false);
			}

			window.addEventListener("resize", handleResize);
			handleResize();

			return () => window.removeEventListener("resize", handleResize);
		}, []);
	}

	return (
		<>
			{user && user.role && (
				<nav
					className={
						"mb-4 fixed" +
						(collapse ? " mobile" : "") +
						(expand ? " expanded" : "")
					}
				>
					<a className='navbar-brand' href='/'>
						{user.brand === null && (
							<div className='nrg-brand'>
								<h1>NRG</h1>
								<img src={nrgLogo} alt='NRG' />
							</div>
						)}
						{user.brand === "mz" && <img src={mzLogo} alt='Move Easy' />}
						{user.brand === "sc" && (
							<h1 className='ml-2'>
								<span style={{ color: "red" }}>Smart</span> City
							</h1>
						)}
						{user.brand === "cl" && (
							<h1 className='ml-2'>
								<span style={{ color: "red" }}>Classic</span> Locators
							</h1>
						)}
					</a>

					{collapse && (
						<div className='expand-container'>
							<Burger onClick={() => toggleExpand(!expand)} isOpen={expand}>
								Expand
							</Burger>
						</div>
					)}
					<div className='nav-container full'>
						<div className='nav-container right'>
							{user.brand && (
								<NavLink
									className='link-btn link-btn-light'
									to={"/agent/" + user.agentID}
								>
									Agent Form
								</NavLink>
							)}
							{user.brand !== "cl" && (
								<>
									<NavLink
										className='link-btn link-btn-light'
										to={"/consent/" + (id ? id : "")}
									>
										Consent Form
									</NavLink>
								</>
							)}

							{user.role !== "user" && (
								<>
									<NavLink
										className='link-btn link-btn-light'
										to={"/control-panel/consent/" + (id ? id : "")}
									>
										View Consents
									</NavLink>
									<NavLink
										className='link-btn link-btn-light'
										to={"/control-panel/leads/" + (id ? id : "")}
									>
										View Leads
									</NavLink>
								</>
							)}
							{(user.role === "admin" || user.role === "manager") && (
								<NavLink
									className='link-btn link-btn-light'
									to='/control-panel/users'
								>
									Manage Users
								</NavLink>
							)}
							<Button
								className='btn btn-profile'
								onClick={() => toggleMenu(!showMenu)}
							>
								{!showMenu ? <img src={icon} alt='' /> : <h1>&#x2715;</h1>}
							</Button>
							<div className={"toggle-menu " + (showMenu ? "" : "hide")}>
								<h6>Hi, {user.name}!</h6>
								<NavLink className='link-btn link-btn-light' to='/account'>
									Update password
								</NavLink>
								<NavLink className='link-btn link-btn-light' to='/logout'>
									Logout
								</NavLink>
							</div>
						</div>
					</div>

					{/* <div className={"toggle-menu " + (showMenu ? "" : "hide")}>
            <h6>Hi, {user.name}!</h6>
            <NavLink className="link-btn link-btn-light" to="/account">
              Update password
            </NavLink>
            <NavLink className="link-btn link-btn-light" to="/logout">
              Logout
            </NavLink>
          </div> */}
				</nav>
			)}
			{user && (user.role === "admin" || user.role === "manager") && (
				<div className='maintenance-banner'>
					Site is in beta. Please report all bugs{" "}
					<a href='mailto:mason.tang@nrg.com?subject=Lead Portal Bug'>
						here
					</a>
				</div>
			)}
		</>
	);
};

export { Nav };
