import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/user";
import { updateTitle } from "../services/utilities";
import auth from "../services/auth";
import { toast } from "react-toastify";
import { Link } from "@reach/router";
// Forms
import ResetForm from "../forms/reset";
import PasswordForm from "../forms/password";
import PasswordVerify from "../forms/password-verify";
// Media
import nrgLogo from "../assets/images/nrg-logo-mark.svg";
import reliantLogo from "../assets/images/reliant-logo.png";

export default function Account({ token, location }) {
  const { user } = useContext(UserContext);
  // Update title
  updateTitle("Lead Capture Portal | Account");

  const [passReset, togglePassReset] = useState(false);
  const [agentID, updateAgentID] = useState(null);
  const prevPath = location.state ? location.state.prevPath : null;

  const checkToken = async () => {
    const { success, error } = await auth.verify({
      token: token,
    });

    success && togglePassReset(true);
    error && toast.error(error.message);
  };

  useEffect(() => {
    token && checkToken();
    if (user.agentID) {
      updateAgentID(user.agentID);
    }
  }, [token]);

  return (
    <>
      <div className="container mxw-600">
        <Link
          className="btn btn-back"
          to={prevPath ? prevPath : "/"}
          title="Back"
        >
          <span className="left-arrow">&#x21e6;</span>
          <h3>Back</h3>
        </Link>
      </div>
      <section className="login">
        <div className="title-block">
          <h2>
            NRG <img src={nrgLogo} alt="NRG" />
          </h2>
          <h2 className="sub-title">
            {passReset ? "Reset Password" : "Account Reset"}
          </h2>
          <img src={reliantLogo} alt="Reliant logo" />
        </div>
        {passReset ? (
          <PasswordForm token={token} agentID={agentID} />
        ) : agentID ? (
          <PasswordVerify
            agentID={agentID}
            verify={(e) => togglePassReset(e)}
          />
        ) : (
          <ResetForm />
        )}
      </section>
    </>
  );
}
