import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { UserContext } from "../context/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUser } from "../services/post";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const AddUser = ({ hide }) => {
  const { user } = useContext(UserContext);
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");
  const [loading, toggleLoading] = useState(false);

  // Schema for contact form
  const schema =
    user.role === "admin"
      ? yup.object().shape({
          fName: yup.string().required("First name is required"),
          lName: yup.string().required("Last name is required"),
          email: yup
            .string()
            .email("Email not valid")
            .required("Email is required"),
          reEmail: yup.string().required("Email is required"),
          phone: yup.string(),
          role: yup.string(),
          brand: yup.string(),
        })
      : yup.object().shape({
          fName: yup.string().required("First name is required"),
          lName: yup.string().required("Last name is required"),
          email: yup
            .string()
            .email("Email not valid")
            .required("Email is required"),
          reEmail: yup.string().required("Email is required"),
          phone: yup.string(),
          role: yup.string(),
        });

  const roles =
    user.role === "admin"
      ? [
          { id: "user", name: "User" },
          { id: "member", name: "Member" },
          { id: "manager", name: "Manager" },
          { id: "admin", name: "Admin" },
        ]
      : [
          { id: "user", name: "User" },
          { id: "member", name: "Member" },
          { id: "manager", name: "Manager" },
        ];

  const brands = [
    { id: "mz", name: "Move Easy" },
    { id: "sc", name: "Smart City" },
    { id: "cl", name: "Classic Locators" },
    { id: "", name: "None" },
  ];

  // Handle validation
  const { register, handleSubmit, errors, control, clear } = useForm({
    resolver: yupResolver(schema),
  });

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  const handleHide = (e) => {
    e.preventDefault();
    hide();
  };

  // Handle form submit
  const onSubmit = async (data) => {
    toggleLoading(true);
    delete data.reEmail;

    const { success } = await createUser(data);

    if (success) {
      toast.success(success.message);
      hide();
    }
    toggleLoading(false);
  };

  return (
    <form className="flex column" onSubmit={handleSubmit(onSubmit)}>
      <div className="container full">
        <h3 className="mb-2">Create a new user</h3>
        <h5>User roles:</h5>
        <ul>
          <li>User - default role for submitting forms.</li>
          <li>Member - can submit forms and view leads.</li>
          <li>Manager - can submit forms, view leads and manage users.</li>
        </ul>
        {(user.role === "admin" || user.brand === null) && (
          <p>
            <span className="required">* </span>
            User must be assigned a brand in order to submit lead forms.
          </p>
        )}
      </div>
      <Button
        text="&#x2715;"
        className="btn btn-close"
        onClick={(e) => handleHide(e)}
      />
      <div className="row">
        <Form.Input
          label="First Name"
          name="fName"
          required={true}
          placeholder="First name"
          register={register}
          error={errors.fName?.message}
        />
        <Form.Input
          type="text"
          label="Last Name"
          name="lName"
          required={true}
          placeholder="Last name"
          register={register}
          error={errors.lName?.message}
        />
        {(user.brand === null || user.role === "admin") && (
          <Form.Select
            label="Brand"
            name="brand"
            values={brands}
            clear={clear}
            register={register}
            error={errors.brands?.message}
          />
        )}
        <Form.Select
          label="Role"
          name="role"
          values={roles}
          clear={clear}
          register={register}
          error={errors.role?.message}
        />
      </div>
      <div className="row">
        <Controller
          name="phone"
          defaultValue=""
          control={control}
          render={(props) => (
            <Form.Input
              type="phone"
              label="Phone number"
              name="phone"
              placeholder="Optional"
              onChange={(e) => props.onChange(e)}
              clear={clear}
              error={errors.phone?.message}
            />
          )}
        />
        <Form.Input
          type="email"
          label="Email"
          name="email"
          required={true}
          placeholder="Corporate email"
          onChange={(e) => compareFields(e, true)}
          clear={clear}
          register={register}
          error={errors.email?.message}
        />
        <Form.Input
          type="email"
          label="Re-enter email"
          name="reEmail"
          required={true}
          placeholder="Corporate email"
          onChange={(e) => compareFields(e)}
          clear={clear}
          register={register}
          error={errors.reEmail?.message}
        />
      </div>
      <div className="row">
        <Button
          text="Create user"
          className="btn btn-purple px-5"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default AddUser;
