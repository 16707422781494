import React, { useState, useEffect, useCallback } from "react";
import "yup-phone";
import Form from "../components/common/inputs";
import { searchFilter } from "../services/utilities";
import Button from "../components/common/button";
import _ from "lodash";

/** -------------------------------
 *# Filters
 * --------------------------------
 */
const Search = ({ update, filterState, source, user, filters = true }) => {
  const [search, updateSearch] = useState("");
  const [state, updateState] = useState("");
  const [verified, updateVerified] = useState(false);

  // Handle input changes
  const filterResults = useCallback(() => {
    let results = [];

    if (filters) {
      results = searchFilter(source, state, ["state", "address"]);
      verified && (results = searchFilter(results, null, "verified"));
    } else results = source;
    !_.isEmpty(search) && (results = searchFilter(results, search));

    update(results);
    filterState && filterState({ filter: state });
  }, [state, verified, search]);

  useEffect(() => {
    filterResults();
  }, [filterResults]);

  const stateFilter = [
    { id: "", name: "All" },
    { id: "excludes:TX", name: "All but Texas" },
    { id: "includes:TX", name: "Only Texas" },
  ];

  const clearSearch = (e) => {
    e.preventDefault();
    updateSearch("");
  };

  return (
    <>
      <form className="search">
        <Button
          text="&#x58;"
          type="btn-search"
          onClick={(e) => clearSearch(e)}
        />
        <Form.Input
          label="Search"
          name="search"
          value={search}
          fullWidth={true}
          onChange={(e) => updateSearch(e)}
        />
      </form>
      {filters && (
        <form className="filters">
          {user && (user.brand === null || user.brand === "mz") && (
            <Form.Select
              label="Filter state"
              name="filterState"
              values={stateFilter}
              selected="all"
              onChange={(e) => updateState(e.target.value)}
            />
          )}
          <Form.CheckboxToggle
            label="Filter verified"
            name="filterVerified"
            onChange={(e) => updateVerified(e)}
          />
        </form>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default Search;
