import React, { useState } from "react";
import Button from "../common/button";
import { verifyConsent } from "../../services/post";

const Consents = ({ v }) => {
  const [expand, toggleExpand] = useState(false);
  const [verify, updateVerify] = useState(v.verified);

  const handleToggle = () => {
    expand ? toggleExpand(false) : toggleExpand(true);
  };

  //# Update verify status
  const handleVerify = async () => {
    const { success, error } = await verifyConsent({
      confirmation: v.confirmation,
    });
    if (success) {
      updateVerify(success.data);
      toggleExpand(!expand);
    }
    error && console.log(error);
  };

  return (
    <div className={"lead-container " + (verify ? "submitted" : "")}>
      <div className="container lead-header">
        <div className="row mb-3">
          <div className="col">
            <h6 className="text-muted">Confirmation #</h6>
            <h5 className="confirmation">{v.confirmation}</h5>
          </div>
          <div className="col-sm-3 text-right">
            <h6 className="text-muted">Submitted</h6>
            <h5>{v.created}</h5>
          </div>
          {verify && (
            <div className="col-sm-3 sent-email">
              <h6 className="text-muted">Verified</h6>
              <h5>{verify}</h5>
            </div>
          )}
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-5">
            <h6 className="text-muted">Customer name</h6>
            <h5>{v.fName + " " + v.lName}</h5>
          </div>
          <div className="col-sm-6">
            <h6 className="text-muted">Broker</h6>
            <h5>{v.brokerName}</h5>
          </div>
          <div className="col-md-1 text-right mt-3 expandable">
            <Button
              text="&#x276F;"
              type={expand ? "btn-expanded" : "btn-collapse"}
              onClick={(e) => handleToggle()}
            />
          </div>
        </div>
      </div>
      {expand && (
        <div className="container lead-collapse">
          <hr />
          <div className="row mb-4">
            <div className="col-sm-6 col-md-3">
              <h6 className="text-muted">Phone</h6>
              <h5>{v.phone}</h5>
            </div>
            <div className="col-sm-6 col-md-3">
              <h6 className="text-muted">Alt phone</h6>
              <h5>{v.altPhone}</h5>
            </div>
            <div className="col-sm-6 col-md-4">
              <h6 className="text-muted">Email</h6>
              <h5>{v.email}</h5>
            </div>
            <div className="col-sm-6 col-md-2">
              <h6 className="text-muted">Contact pref.</h6>
              <h5>{v.timePreference}</h5>
            </div>
          </div>
          <hr />
          <div className="row mb-4">
            <div className="col">
              <h6 className="text-muted">Address</h6>
              <h5>{v.address}</h5>
            </div>
            {v.city && (
              <div className="col-sm-2 col-md-2">
                <h6 className="text-muted">City</h6>
                <h5>{v.city}</h5>
              </div>
            )}
            <div className="col-sm-2 col-md-2">
              <h6 className="text-muted">State</h6>
              <h5>{v.state}</h5>
            </div>
            {v.zip && (
              <div className="col-sm-2 col-md-2">
                <h6 className="text-muted">Zip</h6>
                <h5>{v.zip}</h5>
              </div>
            )}
            <div className="col-sm-2 col-md-2">
              <h6 className="text-muted">Language</h6>
              <h5>{v.lang}</h5>
            </div>
          </div>
          <hr />
          <div className="row mb-4">
            <div className="col-sm-6 col-md-7">
              <h6 className="text-muted">Comments</h6>
              <h5>{v.comments}</h5>
            </div>
            <div className="col-sm-3 col-md-3">
              <h6 className="text-muted">IP Address</h6>
              <h5>{v.ipAddress}</h5>
            </div>
            <div className="col-sm-3 col-md-2">
              <h6 className="text-muted">Closing date</h6>
              <h5>{v.closingDate}</h5>
            </div>
          </div>
          {!verify && (
            <div className="row">
              <div className="col text-right">
                <Button
                  text="Verified"
                  type="btn-purple"
                  onClick={() => handleVerify()}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Consents;
