import React from "react";
import LoginForm from "../forms/login";
import { updateTitle } from "../services/utilities";
import nrgLogo from "../assets/images/nrg-logo-mark.svg";
import reliantLogo from "../assets/images/reliant-logo.png";
import { Link } from "@reach/router";

export default function Login(param) {
  // Update title
  updateTitle("Lead Capture Portal");

  return (
    <>
      <section className="login">
        <div className="title-block">
          <h2>
            NRG <img src={nrgLogo} alt="NRG" />
          </h2>
          <h2 className="sub-title"> Lead Capture Portal</h2>
          <img src={reliantLogo} alt="Reliant logo" />
        </div>
        <LoginForm />
        <hr style={{ width: "90%", marginTop: 0 }} />
        <h5 className="pb-4 text-center">
          <Link to="/account">Forgot your login information?</Link>
        </h5>
      </section>

      <section className="login">
        <div className="p-5">
          <h4>Just trying to sign your forms?</h4>
          <h5>
            <Link to="/consent">Head here for the consent form</Link>
          </h5>
        </div>
      </section>
    </>
  );
}
