import React, { useState, useEffect, useCallback } from "react";
import { getUsers } from "../../services/get";
import { deleteUser } from "../../services/post";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import { updateTitle } from "../../services/utilities";
import { Nav } from "../nav";
import Search from "../../forms/search";
import UserTable from "./table-users";
import Button from "../common/button";
import Loading from "../common/loading";
import AddUser from "../../forms/addUser";
import _ from "lodash";

export default function Users({ id }) {
  // Update title
  updateTitle("Control Panel | Users");

  const [override, toggleOverride] = useState(false);
  const [data, updateData] = useState([{}]);
  const [filtered, updateFiltered] = useState(null);
  const [message, showMessage] = useState(false);
  const [idFilter, updateIDFilter] = useState([{}]);
  const [loading, toggleLoading] = useState(true);
  const [addUser, toggleAddUser] = useState(false);
  // Paginate
  const [total, updateTotal] = useState(0);
  const [paginated, updatePaginated] = useState([{}]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, updatePerPage] = useState(12);

  const updateReturn = useCallback(async () => {
    toggleLoading(true);
    const { success, error } = await getUsers();
    if (success) {
      if (id && !override) {
        const data = success.data.filter((user) => user.agentID === id);
        success.data && updateData(success.data);
        success.message && showMessage(success.message);
        if (data) {
          updateTotal(1);
          updateIDFilter(data);
          paginateResults(data);
        }
      } else {
        if (success.data) {
          updateTotal(success.total);
          updateData(success.data);
          paginateResults(success.data);
        }
        success.message && showMessage(success.message);
      }
    }
    setTimeout(() => {
      toggleLoading(false);
    }, 700);
    error && console.log(error);
  }, [id, override]);

  useEffect(() => {
    updateReturn();
  }, [updateReturn]);

  const showAll = () => {
    override ? paginateResults(idFilter) : paginateResults(data);
    toggleOverride(!override);
  };

  const paginateResults = (values = null, pageNumber = null, pages = null) => {
    let offset = 0;

    pageNumber
      ? (offset = (pageNumber - 1) * (pages ? pages : perPage))
      : (offset = (currentPage - 1) * (pages ? pages : perPage));

    values === null && (values = data);
    updateTotal(values.length);

    const pageData = values
      .slice(offset, offset + (pages ? pages : perPage))
      .map((v) => v);

    updatePaginated(pageData);
  };

  const handlePageChange = (pageNumber) => {
    filtered.length > 1
      ? paginateResults(filtered, pageNumber)
      : paginateResults(data, pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleFiltered = (data) => {
    updateFiltered(data);
    paginateResults(data);
  };

  const handleEdit = (edit) => {
    let updated = [];

    data.forEach((v) => {
      if (v.agentId === edit.agentId) {
        updated.push(edit);
      } else updated.push(v);
    });

    updateFiltered(updated);
    paginateResults(updated);
  };

  const handleDelete = async (id) => {
    const { success, error } = await deleteUser({ id: id });
    if (success) {
      const updated = _.isEmpty(filtered[0])
        ? data.filter((v) => v.agentId !== id)
        : filtered.filter((v) => v.agentId !== id);
      handleFiltered(updated);
      toast.success(success.message);
    }
    error && console.error(error);
  };

  console.log(filtered);
  console.log(data);
  return (
    <>
      <Nav />
      <div className="container-fluid flex-layout">
        <div className="container mt-3 flex column">
          <Search
            source={data}
            filters={false}
            clear={() => handleFiltered(null)}
            update={(e) => handleFiltered(e)}
          />
          {!addUser && (
            <Button
              text="&#x2b; New user"
              className="btn btn-success ml-auto mr-2"
              onClick={() => toggleAddUser(!addUser)}
            />
          )}
        </div>
        {addUser && (
          <div className="container-fluid side-nav">
            <section>
              <AddUser hide={() => toggleAddUser(!addUser)} />
            </section>
          </div>
        )}
        <div className="container flex apart mxw-900 mt-5">
          <h1 className="title">Users</h1>
          <div>
            {id && (
              <Button
                className="btn btn-secondary"
                onClick={() => showAll()}
                text={!override ? "Show All" : "Show One"}
              />
            )}
          </div>
        </div>
        <div className="container lead-wrapper users">
          <Loading loading={loading} message="Getting users">
            {message ? (
              <section className="mini p-4 text-center">
                <h2>{message}</h2>
              </section>
            ) : (
              <>
                {paginated &&
                  paginated.map((v) => (
                    <UserTable
                      key={v.agentId}
                      v={v}
                      handleDelete={(e) => handleDelete(e)}
                      handleEdit={(e) => handleEdit(e)}
                    />
                  ))}

                {total > perPage && (
                  <Pagination
                    hideDisabled
                    hideNavigation
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={7}
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </>
            )}
          </Loading>
        </div>
      </div>
    </>
  );
}
