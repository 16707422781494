import React from "react";
import Consent from "../forms/consent";
import MZ from "../forms/mz/consent";
import SC from "../forms/sc/consent";
import CL from "../forms/cl/consent";

const Submit = (props) => {
  switch (props.user.brand) {
    case process.env.REACT_APP_MOVE_EZ:
      return <MZ customer_id={props.customer_id} agent_id={props.agent_id} />;
    case process.env.REACT_APP_SMART_CITY:
      return <SC customer_id={props.customer_id} agent_id={props.agent_id}/>;
    case process.env.REACT_APP_CLASSIC_LOCATORS:
      return <CL customer_id={props.customer_id} agent_id={props.agent_id}/>;
    default:
      return <Consent customer_id={props.customer_id} agent_id={props.agent_id}/>;
  }
};

export default Submit;
