import React from "react";
import { requireLogin } from "../services/auth";

const PrivateRoute = ({ as: Component, ...props }) => {
  return (
    <div>
      {props.role && props.role !== "standard" ? (
        <Component {...props} />
      ) : (
        requireLogin()
      )}
    </div>
  );
};

export default PrivateRoute;
