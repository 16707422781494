import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";
import auth from "../services/auth";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const ResetForm = () => {
  const [loading, toggleLoading] = useState(false);

  // Schema for contact form
  const schema = yup.object().shape({
    email: yup.string().email("Email not valid").required("Required"),
  });

  // Handle validation
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle form submit
  const onSubmit = async (data) => {
    toggleLoading(true);
    console.log(data);
    const { success, error } = await auth.forgot(data);
    error && toast.error(error.message);

    if (success) {
      toast.success(success.message);
      setTimeout(() => {
        window.location.replace("/");
      }, 3000);
    }
    toggleLoading(false);
  };

  return (
    <form className="login" onSubmit={handleSubmit(onSubmit)}>
      <Form.Input
        label="Email"
        name="email"
        fullWidth={true}
        small="Enter your corporate email. If your email exists, you will be sent an
        email with reset instructions."
        register={register}
        error={errors.email?.message}
      />
      <Button
        text="Send email"
        className="btn btn-primary solid square px-5"
        type="submit"
        loading={loading}
      />
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ResetForm;
