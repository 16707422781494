import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUser } from "../services/post";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const EditUsers = ({ user, auth, handleEdit }) => {
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");

  const schema =
    user.role === "admin"
      ? yup.object().shape({
          fName: yup.string(),
          lName: yup.string(),
          email: yup.string().email("Email not valid"),
          reEmail: yup.string(),
          phone: yup.string(),
          role: yup.string(),
          brand: yup.string(),
        })
      : yup.object().shape({
          fName: yup.string(),
          lName: yup.string(),
          email: yup.string().email("Email not valid"),
          reEmail: yup.string(),
          phone: yup.string(),
          role: yup.string(),
        });

  const roles =
    user.role === "admin"
      ? [
          { id: "user", name: "User" },
          { id: "member", name: "Member" },
          { id: "manager", name: "Manager" },
          { id: "admin", name: "Admin" },
        ]
      : [
          { id: "user", name: "User" },
          { id: "member", name: "Member" },
          { id: "manager", name: "Manager" },
        ];

  const brands = [
    { id: "mz", name: "Move Easy" },
    { id: "sc", name: "Smart City" },
    { id: "cl", name: "Classic Locators" },
    { id: "", name: "None" },
  ];

  // Handle validation
  const { register, handleSubmit, errors, control, clear } = useForm({
    resolver: yupResolver(schema),
  });

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  // Handle form submit
  const onSubmit = async (data) => {
    data.agentId = user.agentId;
    const { success, error } = await updateUser(data);

    if (success) {
      success.message && toast.success(success.message);
      handleEdit(success.data);
    }
    error && toast.error(error.message);
  };

  return (
    <form className="users" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-4">
          <Form.Input
            label="First Name"
            name="fName"
            fullWidth={true}
            placeholder={user.fName}
            register={register}
            error={errors.fName?.message}
          />
        </div>
        <div className="col-md-4">
          <Form.Input
            type="text"
            label="Last Name"
            name="lName"
            fullWidth={true}
            placeholder={user.lName}
            register={register}
            error={errors.lName?.message}
          />
        </div>
        {auth.role === "admin" && (
          <div className="col-md-2">
            <Form.Select
              label="Brand"
              name="brand"
              values={brands}
              fullWidth={true}
              selected={user.brand ? user.brand : ""}
              clear={clear}
              register={register}
              error={errors.brands?.message}
            />
          </div>
        )}
        <div className="col-md-2">
          <Form.Select
            label="Role"
            name="role"
            values={roles}
            fullWidth={true}
            selected={user.role}
            clear={clear}
            register={register}
            error={errors.role?.message}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Controller
            name="phone"
            defaultValue=""
            control={control}
            render={(props) => (
              <Form.Input
                type="phone"
                label="Phone number"
                name="phone"
                fullWidth={true}
                placeholder={user.phone}
                onChange={(e) => props.onChange(e)}
                clear={clear}
                error={errors.phone?.message}
              />
            )}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <Form.Input
            type="email"
            label="Email"
            name="email"
            fullWidth={true}
            placeholder={user.email}
            onChange={(e) => compareFields(e, true)}
            clear={clear}
            register={register}
            error={errors.email?.message}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <Form.Input
            type="email"
            label="Re-enter email"
            name="reEmail"
            fullWidth={true}
            placeholder={user.email}
            onChange={(e) => compareFields(e)}
            clear={clear}
            register={register}
            error={errors.reEmail?.message}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button text="Save" className="btn btn-purple px-5" type="submit" />
        </div>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default EditUsers;
