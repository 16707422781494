import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { updateTitle, scrollToTop } from "../../services/utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../../components/common/inputs";
import Button from "../../components/common/button";
import { CardInline } from "../../components/common/cards";
import { submitConsent } from "../../services/post";
import { Nav } from "../../components/nav";
// Zipcode list
import zipcodes from "../../assets/zipcodes/all.json";
// Media
import nrgLogo from "../../assets/images/nrg-logo-mark.svg";
import reliantLogo from "../../assets/images/reliant-logo.png";

/** -------------------------------
 *# Smart City Customer Form
 * --------------------------------
 */
const SC = ({ customer_id }) => {
  // Update title
  updateTitle("Smart City | Customer Consent");

  // Set state
  const [showTimes, toggleTimes] = useState(false);
  const [notServiceable, toggleServiceable] = useState(false);
  const [selectedTime, changeTimes] = useState("");
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");
  const [clear, setClear] = useState("false");
  const [show, toggleShow] = useState(true);
  const [loading, toggleLoading] = useState(false);

  // Schema for contact form
  const schema = yup.lazy((value) => {
    if (value !== "undefined") {
      return yup.object().shape({
        fName: yup.string().required("Required"),
        lName: yup.string().required("Required"),
        address: yup.string().required("Required"),
        city: yup.string().required("Required"),
        closingDate: yup.string().required("Required"),
        brokerName: yup.string().required("Required"),
        timePreference: yup.string().required("Required"),
        timeSelection: yup.string().required("Please select a time"),
        consentReliant: yup.string().required("Required"),
        consentNRG: yup.string().required("Required"),
        email: yup.string().email("Email not valid").required("Required"),
        reEmail: yup.string().required("Required"),
        phone: yup.string().min(12).max(12).required(""),
        zip: yup.string().min(5).required("Required"),
        message: yup.string().max(1000),
      });
    }
    return yup.mixed().notRequired();
  });

  // Handle validation
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  // Form variables
  const states = [
    { id: "IL", name: "Illinois" },
    { id: "MA", name: "Massachusetts" },
    { id: "MD", name: "Maryland" },
    { id: "NJ", name: "New Jersey" },
    { id: "OH", name: "Ohio" },
    { id: "PA", name: "Pennsylvania" },
    { id: "TX", name: "Texas" },
  ];

  const languages = [
    { id: "EN", name: "English" },
    { id: "ES", name: "Spanish" },
  ];

  const times = [
    { id: "", name: "Select best time" },
    { id: "morning", name: "Morning" },
    { id: "afternoon", name: "Afternoon" },
    { id: "evening", name: "Evening" },
  ];

  const selectableTimes = {
    morning: [
      { name: "timeSelection", id: "t9", value: "9am" },
      { name: "timeSelection", id: "t10", value: "10am" },
      { name: "timeSelection", id: "t11", value: "11am" },
    ],
    afternoon: [
      { name: "timeSelection", id: "t12", value: "12pm" },
      { name: "timeSelection", id: "t1", value: "1pm" },
      { name: "timeSelection", id: "t2", value: "2pm" },
      { name: "timeSelection", id: "t3", value: "3pm" },
    ],
    evening: [{ name: "timeSelection", id: "t4", value: "4pm" }],
  };

  const consent = {
    Reliant: [
      {
        name: "consentReliant",
        id: "consentYes",
        value:
          "Yes, I agree that Reliant may contact me about energy products and services and other home services that Reliant may make available through its affiliates or third parties, as described above.",
      },
      {
        name: "consentReliant",
        id: "consentNo",
        value: "No, I do not wish to be contacted by Reliant",
      },
    ],
    NRG: [
      {
        name: "consentNRG",
        id: "consentYesNRG",
        value:
          "Yes, I agree that Reliant Energy Retail Services, LLC ('NRG Home') may contact me",
      },
      {
        name: "consentNRG",
        id: "consentNoNRG",
        value: "No, I do not wish to be contacted by NRG Home",
      },
    ],
  };

  // Display/hide time slots
  const selectTime = (e) => {
    toggleTimes(true);
    const selected = e.target.value;
    if (selected !== "") {
      changeTimes(selectableTimes[e.target.value]);
    } else {
      toggleTimes(false);
      changeTimes("");
    }
  };

  // Check for qualified zip
  const validateZip = (zip) => {
    // Check once code has been completely entered
    if (zip.length === 5) {
      zipcodes.includes(zip)
        ? toggleServiceable(false)
        : toggleServiceable(true);
    } else {
      toggleServiceable(false);
    }
    return zip;
  };

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  // Handle form submit
  const onSubmit = async (data) => {
    if (!notServiceable) {
      toggleLoading(true);
      // Update and remove time values
      data.timePreference = data.timeSelection;
      if(customer_id) data.customer_id = customer_id
      delete data.timeSelection;
      delete data.reEmail;

      const { success, error } = await submitConsent(data);
      if (success) {
        console.log(success);
        scrollToTop();
        resetForm();
        toast.success(success.message);
      } else {
        error && console.log(error);
        toast.error(error);
      }
      toggleLoading(false);
    }
  };

  // Initialize form
  const resetForm = () => {
    setClear("true");
    reset();
    setClear("false");
    toggleTimes(false);
  };
  return (
    <>
      <Nav id={customer_id} />
      {customer_id && (
        <section className="mini">
          <CardInline
            id="cid"
            title="Customer ID:"
            text={customer_id}
            hide={() => toggleShow(false)}
          />
        </section>
      )}
      <section className="form">
        <div className="title-block blue">
          <h2>
            NRG <img src={nrgLogo} alt="NRG" />
          </h2>
          <h2 className="sub-title">Customer Consent Form</h2>
          <img src={reliantLogo} alt="Reliant logo" />
        </div>
        <div className="container">
          <form className="form contact-us" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <small className="header">Consumer's Information</small>
              <hr />
            </div>
            <div className="row">
              <Form.Input
                label="Consumer’s first name"
                name="fName"
                placeholder="Enter first name"
                required={true}
                clear={clear}
                register={register}
                error={errors.fName?.message}
              />
              <Form.Input
                label="Consumer’s last name"
                name="lName"
                placeholder="Enter last name"
                required={true}
                clear={clear}
                register={register}
                error={errors.lName?.message}
              />
            </div>
            <div className="row">
              <small className="header">Consumer’s Contact information</small>
              <hr />
            </div>
            <div className="row spaced-out">
              <Form.Input
                label="Address"
                name="address"
                placeholder="Enter address"
                required={true}
                clear={clear}
                register={register}
                error={errors.address?.message}
              />
              <Form.Input
                label="City"
                name="city"
                placeholder="Enter city"
                required={true}
                clear={clear}
                register={register}
                error={errors.city?.message}
              />
              <Form.Select
                label="State"
                name="state"
                values={states}
                selected="TX"
                required={true}
                clear={clear}
                register={register}
                error={errors.state?.message}
              />
              <Controller
                name="zip"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="zipcode"
                    label="Zipcode"
                    name="zip"
                    placeholder="54123"
                    onChange={(e) => props.onChange(validateZip(e))}
                    required={true}
                    clear={clear}
                    error={errors.zip?.message}
                  />
                )}
              />
            </div>
            {notServiceable && (
              <div id="serviceable">
                <p>The provided zipcode is not currently serviceable.</p>
              </div>
            )}
            <div className="row spaced-out">
              <Controller
                name="phone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="phone"
                    label="Phone number"
                    name="phone"
                    placeholder="___ - ___ - ____"
                    onChange={(e) => props.onChange(e)}
                    required={true}
                    clear={clear}
                    error={errors.phone?.message}
                  />
                )}
              />
              <Controller
                name="altPhone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="phone"
                    label="Alt phone number"
                    name="altPhone"
                    placeholder="___ - ___ - ____"
                    onChange={(e) => props.onChange(e)}
                    clear={clear}
                    register={register}
                    error={errors.altPhone?.message}
                  />
                )}
              />
              <Form.Input
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email address"
                required={true}
                onChange={(e) => compareFields(e, true)}
                clear={clear}
                register={register}
                error={errors.email?.message}
              />
              <Form.Input
                type="email"
                label="Re-enter email"
                name="reEmail"
                placeholder="Enter email address"
                required={true}
                onChange={(e) => compareFields(e)}
                clear={clear}
                register={register}
                error={errors.reEmail?.message}
              />
            </div>
            <div className="row">
              <small className="header">Consumer’s Preferences</small>
              <hr />
            </div>
            <div className="row">
              <Form.Select
                label="Preferred language"
                name="lang"
                values={languages}
                clear={clear}
                register={register}
                error={errors.lang?.message}
              />
              <Form.Select
                label="Appointment"
                name="timePreference"
                values={times}
                required={true}
                clear={clear}
                onChange={(e) => selectTime(e)}
                register={register}
                error={errors.timePreference?.message}
              />
              {showTimes && (
                <Form.RadioInline
                  label="Select a time"
                  values={selectedTime}
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.timeSelection?.message}
                />
              )}
              <Form.Input
                type="date"
                label="Closing date"
                name="closingDate"
                required={true}
                clear={clear}
                register={register}
                error={errors.closingDate?.message}
              />
            </div>
            <div className="row">
              <Form.Textarea
                label="Comments"
                name="comments"
                placeholder="Leave any additional information here"
                small="Note to agent: NRG will contact customer 2 weeks prior to closing date"
                fullWidth={true}
                cols="30"
                rows="5"
                clear={clear}
                register={register}
              />
            </div>
            <div className="row">
              <small className="header">Agent Information</small>
              <hr />
            </div>
            <div className="row">
              <Form.Input
                label="Brokerage"
                name="brokerName"
                placeholder="Enter broker name"
                required={true}
                clear={clear}
                register={register}
                error={errors.brokerName?.message}
              />
            </div>
            <div className="row">
              <p>
                {/* By clicking “Submit” you represent that the contact information
                submitted belongs to you and that you hereby authorized Reliant
                Energy Retail Services, LLC (“Reliant”) or Reliant Energy
                Northeast LLC (“NRG Home”) to contact you for marketing purposes
                at the telephone number, email address, or mobile number you
                provide on this form and that you authorize Reliant or NRG Home
                to use automated telephone technology including auto-dialers and
                text messages, even if your telephone or mobile number is
                currently listed on any federal, state, or corporate “Do Not
                Call” list. You are not required to give your consent as a
                condition of purchase. */}

By clicking, <strong>“Submit”</strong> you agree that Reliant Energy Retail Services, LLC (“Reliant”) may contact you about energy products and services and other home services that Reliant may make available through its affiliates or third parties, and that your telephone, email address, and/or mobile number you provide on this form has been provided by you, belongs to you, and is accurate. Furthermore, you authorize Reliant to contact you for marketing purposes at the telephone number, mobile number or email address you provide on this form using automated telephone technology including auto-dialers and text messages, even if your telephone or mobile number is currently listed on any federal, state, or Reliant’s corporate “Do Not Call” list. You are not required to give your consent as a condition of purchase.
             </p>
            </div>
            <div className="row">
              <Form.RadioInline
                label="Reliant Consent"
                values={consent.Reliant}
                required={true}
                clear={clear}
                register={register}
                error={errors.consentReliant?.message}
              />
              <Form.RadioInline
                label="NRG Consent"
                values={consent.NRG}
                required={true}
                clear={clear}
                register={register}
                error={errors.consentNRG?.message}
              />
            </div>
            <div className="row">
              <hr />
            </div>
            <Button text="Submit" className="btn btn-success" type="submit" />
          </form>
        </div>
      </section>
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default SC;
