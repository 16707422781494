import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { UserContext } from "../../context/user";
import {
  updateTitle,
  scrollToTop,
  copyReceipt,
} from "../../services/utilities";
import { submitLead } from "../../services/post";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../../components/common/inputs";
import Button from "../../components/common/button";
import { CardInline } from "../../components/common/cards";
// Zipcode list
import zipcodes from "../../assets/zipcodes/all.json";
// Media
import nrgLogo from "../../assets/images/nrg-logo-mark.svg";
import reliantLogo from "../../assets/images/reliant-logo.png";

/** -------------------------------
 *# Move Ez Agent Form
 * --------------------------------
 */
const MZ = () => {
  // Update title
  updateTitle("Move Ez | Lead Capture");
  const { user } = useContext(UserContext);

  // Set state
  const [showTimes, toggleTimes] = useState(false);
  const [notServiceable, toggleServiceable] = useState(false);
  const [selectedTime, changeTimes] = useState("");
  const [comparePrimary, changePrimary] = useState("");
  const [compareSecondary, changeSecondary] = useState("");
  const [confirmation, updateConf] = useState("");
  const [clear, setClear] = useState("false");
  const [loading, toggleLoading] = useState(false);
  const [showType, toggleType] = useState(true);

  // Schema for contact form
  const schema = yup.lazy((value) => {
    if (value.zip === "" || value.phone === "") {
      scrollToTop();
    }
    if (value !== "undefined") {
      return yup.object().shape({
        leadType: yup.string().required("Required"),
        fName: yup.string().required("Required"),
        lName: yup.string().required("Required"),
        agentFName: yup.string().required("Required"),
        agentLName: yup.string().required("Required"),
        address: yup.string().required("Required"),
        city: yup.string().required("Required"),
        closingDate: yup.string().required("Required"),
        agentName: yup.string(),
        brokerName: yup.string().required("Required"),
        timePreference: yup.string().required("Required"),
        timeSelection: yup.string().required("Please select a time"),
        email: yup.string().email("Email not valid").required("Required"),
        reEmail: yup.string().required("Required"),
        gasAndElectricity: yup.string().required("Required"),
        phone: yup.string().length(12, "Incorrect format"),
        zip: yup.string().min(5).required("Required"),
        message: yup.string().max(1000),
      });
    }
    return yup.mixed().notRequired();
  });

  // Handle validation
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  // Form variables
  const leadType = [
    { id: "warm", name: "Warm lead" },
    { id: "lead", name: "Standard lead" },
  ];

  const electricity = [
    { id: "", name: "" },
    { id: "MoveEasy set up Gas and Electricity", name: "MoveEasy set up Gas & Electricity" },
    { id: "MoveEasy did NOT set up Gas and Electricity", name: "MoveEasy did NOT set up Gas & Electricity" },
    { id: "Texas resident – does not apply", name: "Texas resident – does not apply" },
  ];

  const states = [
    { id: "IL", name: "Illinois" },
    { id: "MA", name: "Massachusetts" },
    { id: "MD", name: "Maryland" },
    { id: "NJ", name: "New Jersey" },
    { id: "OH", name: "Ohio" },
    { id: "PA", name: "Pennsylvania" },
    { id: "TX", name: "Texas" },
  ];

  const languages = [
    { id: "EN", name: "English" },
    { id: "ES", name: "Spanish" },
  ];

  const times = [
    { id: "", name: "Select best time" },
    { id: "morning", name: "Morning" },
    { id: "afternoon", name: "Afternoon" },
    { id: "evening", name: "Evening" },
  ];

  const selectableTimes = {
    morning: [
      { name: "timeSelection", id: "t9", value: "9am" },
      { name: "timeSelection", id: "t10", value: "10am" },
      { name: "timeSelection", id: "t11", value: "11am" },
    ],
    afternoon: [
      { name: "timeSelection", id: "t12", value: "12pm" },
      { name: "timeSelection", id: "t1", value: "1pm" },
      { name: "timeSelection", id: "t2", value: "2pm" },
      { name: "timeSelection", id: "t3", value: "3pm" },
    ],
    evening: [{ name: "timeSelection", id: "t4", value: "4pm" }],
  };

  // Display/hide time slots
  const selectTime = (e) => {
    toggleTimes(true);
    const selected = e.target.value;
    if (selected !== "") {
      changeTimes(selectableTimes[e.target.value]);
    } else {
      toggleTimes(false);
      changeTimes("");
    }
  };

  // Check for qualified zip
  const validateZip = (zip) => {
    // Check once code has been completely entered
    if (zip.length === 5) {
      zipcodes.includes(zip)
        ? toggleServiceable(false)
        : toggleServiceable(true);
    } else {
      toggleServiceable(false);
    }
    return zip;
  };

  // Validate email fields
  const compareFields = (value, primary = false) => {
    // Set state
    primary ? changePrimary(value) : changeSecondary(value);
    // Reset errors
    errors.reEmail = {};
    // Check secondary
    if (!primary && comparePrimary !== "") {
      (!value.startsWith(comparePrimary) ||
        value.length > comparePrimary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
      // Check primary
    } else if (
      value !== "" &&
      comparePrimary !== "" &&
      compareSecondary !== ""
    ) {
      (!value.startsWith(compareSecondary) ||
        value.length > compareSecondary.length) &&
        (errors.reEmail = { message: "Email addresses do not match" });
    }
  };

  const setType = (e) => {
    e.target.value === "TX" ? toggleType(true) : toggleType(false);
  };

  // Handle form submit
  const onSubmit = async (data) => {
    if (!notServiceable) {
      toggleLoading(true);
      // Update and remove time values
      data.timePreference = data.timeSelection;
      delete data.timeSelection;
      delete data.reEmail;

      const combineAgentName = {agentName: data.agentFName + ' ' + data.agentLName};
      const updatedData = {...data, ...combineAgentName};
      delete updatedData.agentFName;
      delete updatedData.agentLName;

      console.log(updatedData);
      const { success, error } = await submitLead(updatedData);
      if (success) {
        console.log(success);
        success.confirmation && updateConf(success.confirmation);
        scrollToTop();
        resetForm();
        toast.success("Form submitted!");
      } else {
        error && console.log(error);
        toast.error(error);
      }
      toggleLoading(false);
    }
  };

  // Initialize form
  const resetForm = () => {
    setClear("true");
    reset();
    setClear("false");
    toggleTimes(false);
  };

  return (
    <>
      {confirmation !== "" && (
        <section>
          <CardInline
            id="confirmation"
            title="Confirmation"
            text={confirmation}
            button="Save to Clipboard"
            onClick={() => copyReceipt(confirmation)}
            hide={() => updateConf("")}
          />
        </section>
      )}
      <section className="form">
        <div className="title-block">
          <h2>
            NRG <img src={nrgLogo} alt="NRG" />
          </h2>
          <h2 className="sub-title"> Lead Capture Portal</h2>
          <img src={reliantLogo} alt="Reliant logo" />
        </div>
        <div className="container">
          <form
            id="form"
            className="form contact-us"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <small className="header">Representations and Warranties</small>
              <p>
                By clicking “Submit” you represent and warrant to Reliant Energy
                Retail Services, LLC (“Reliant”) and Reliant Energy Northeast
                LLC (“NRG Home”) that you have already obtained the consent from
                the consumer identified on this lead that enables Reliant or NRG
                Home to contact the consumer. You also represent and warrant
                that such consent occurred verbally while you were speaking to
                the consumer on a MOVE EZ recorded line and that such consent
                fully complies with the Federal Telephone Consumer Protection
                Act (47 U.S.C. Section 277, as amended), the federal
                Telemarketing and Consumer Fraud and Prevention Act (15 U.S.C.
                Section 6101-6108, as amended), the Do Not Call Implementation
                Act of 2003 (15 U.S.C. §§6151-6155, as amended), and any state
                or local laws or regulations.
              </p>
            </div>
            <div className="row">
              <small className="header">Consumer's Information</small>
              <hr />
            </div>
            <div className="row">
              <input
                type="hidden"
                name="brand"
                value={user.brand}
                ref={register}
              />
              <input
                type="hidden"
                name="agentID"
                value={user.agentID}
                ref={register}
              />
              <Form.Input
                label="Consumer’s first name"
                name="fName"
                placeholder="Enter first name"
                required={true}
                clear={clear}
                register={register}
                error={errors.fName?.message}
              />
              <Form.Input
                label="Consumer’s last name"
                name="lName"
                placeholder="Enter last name"
                required={true}
                clear={clear}
                register={register}
                error={errors.lName?.message}
              />
            </div>
            <div className="row">
              <small className="header">Consumer’s Contact information</small>
              <hr />
            </div>
            <div className="row spaced-out">
              <Form.Input
                label="Address"
                name="address"
                placeholder="Enter address"
                required={true}
                clear={clear}
                register={register}
                error={errors.address?.message}
              />
              <Form.Input
                label="City"
                name="city"
                placeholder="Enter city"
                required={true}
                clear={clear}
                register={register}
                error={errors.city?.message}
              />
              <Controller
                name="state"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Select
                    label="State"
                    name="state"
                    values={states}
                    selected="TX"
                    onChange={(e) => props.onChange(setType(e))}
                    clear={clear}
                    register={register}
                    error={errors.state?.message}
                  />
                )}
              />
              <Controller
                name="zip"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="zipcode"
                    label="Zipcode"
                    name="zip"
                    placeholder="54123"
                    onChange={(e) => props.onChange(validateZip(e))}
                    required={true}
                    clear={clear}
                    error={errors.zip?.message}
                  />
                )}
              />
            </div>
            {notServiceable && (
              <div id="serviceable">
                <p>The provided zipcode is not currently serviceable.</p>
              </div>
            )}
            <div className="row spaced-out">
              <Controller
                name="phone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="phone"
                    label="Phone number"
                    name="phone"
                    placeholder="___ - ___ - ____"
                    onChange={(e) => props.onChange(e)}
                    required={true}
                    clear={clear}
                    register={register}
                    error={errors.phone?.message}
                  />
                )}
              />
              <Controller
                name="altPhone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Form.Input
                    type="phone"
                    label="Alt phone number"
                    name="altPhone"
                    placeholder="___ - ___ - ____"
                    onChange={(e) => props.onChange(e)}
                    clear={clear}
                    error={errors.altPhone?.message}
                  />
                )}
              />
              <Form.Input
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email address"
                required={true}
                onChange={(e) => compareFields(e, true)}
                clear={clear}
                register={register}
                error={errors.email?.message}
              />
              <Form.Input
                type="email"
                label="Re-enter email"
                name="reEmail"
                placeholder="Enter email address"
                required={true}
                onChange={(e) => compareFields(e)}
                clear={clear}
                register={register}
                error={errors.reEmail?.message}
              />
            </div>
            <div className="row">
              <small className="header">Consumer’s Preferences</small>
              <hr />
            </div>
            <div className="row">
              <Form.Select
                label="Preferred language"
                name="lang"
                values={languages}
                clear={clear}
                register={register}
                error={errors.lang?.message}
              />
              <Form.Select
                label="Appointment"
                name="timePreference"
                values={times}
                required={true}
                clear={clear}
                onChange={(e) => selectTime(e)}
                register={register}
                error={errors.timePreference?.message}
              />
              {showTimes && (
                <Form.RadioInline
                  label="Select a time"
                  values={selectedTime}
                  required={true}
                  clear={clear}
                  register={register}
                  error={errors.timeSelection?.message}
                />
              )}
              <Form.Input
                type="date"
                label="Closing date"
                name="closingDate"
                required={true}
                clear={clear}
                register={register}
                error={errors.closingDate?.message}
              />
            </div>
            <div className="row">
              <Form.Textarea
                label="Comments"
                name="comments"
                placeholder="Leave any additional information here"
                small="Note to agent: NRG will contact customer 2 weeks prior to closing date"
                fullWidth={true}
                cols="30"
                rows="5"
                clear={clear}
                register={register}
              />
            </div>
            <div className="row">
              <Form.Input
                label="Move EZ Agent First Name"
                name="agentFName"
                placeholder="Enter agent name"
                clear={clear}
                register={register}
                required={true}
                error={errors.agentFName?.message}
              />
              <Form.Input
                label="Move EZ Agent Last Name"
                name="agentLName"
                placeholder="Enter agent name"
                clear={clear}
                register={register}
                required={true}
                error={errors.agentLName?.message}
              />
              <Form.Input
                label="Brokerage"
                name="brokerName"
                placeholder="Enter broker name"
                required={true}
                clear={clear}
                register={register}
                error={errors.brokerName?.message}
              />
              <Form.Select
                label="Lead type"
                name="leadType"
                values={leadType}
                selected="lead"
                clear={clear}
                register={register}
                error={errors.leadType?.message}
              />
               <Form.Select
                label="Gas & Electricity"
                name="gasAndElectricity"
                values={electricity}
                selected=""
                clear={clear}
                register={register}
                required={true}
                error={errors.gasAndElectricity?.message}
              />
            </div>
            <div className="row">
              <hr />
            </div>
            <Button
              text="Submit"
              className="btn btn-success"
              type="submit"
              loading={loading}
            />
          </form>
        </div>
      </section>
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default MZ;
