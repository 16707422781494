import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import session from "../services/session";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import Button from "../components/common/button";
import auth from "../services/auth";

/** -------------------------------
 *# HTMLReporter Login Form
 * --------------------------------
 */
const PasswordVerify = ({ agentID, verify }) => {
  // Schema for contact form
  const schema = yup.object().shape({
    password: yup.string().min(6).required("Password is required"),
  });

  // Handle validation
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle form submit
  const onSubmit = async (data) => {
    console.log(data);
    data.agentID = agentID;
    const { success } = await auth.login(data);
    if (success) {
      verify(true);
    } else {
      verify(false);
    }
  };

  return (
    <form className="login" onSubmit={handleSubmit(onSubmit)}>
      <Form.Input
        type="password"
        label="Current Password"
        small="Verify your current password first."
        name="password"
        fullWidth={true}
        register={register}
        error={errors.password?.message}
      />
      <Button
        text="Verify"
        className="btn btn-primary solid square px-5"
        type="submit"
      />
    </form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default PasswordVerify;
