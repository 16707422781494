import React, { useState, useEffect, useCallback, useContext } from "react";
import { UserContext } from "../../context/user";
import { getLeads, getLeadsExport } from "../../services/get";
import Pagination from "react-js-pagination";
import { Nav } from "../nav";
import { updateTitle } from "../../services/utilities";
import Search from "../../forms/search";
import Lead from "./table-leads";
import Button from "../common/button";
import Loading from "../common/loading";

export default function Leads(props) {
  // Update title
  updateTitle("Control Panel | Leads");

  const { user } = useContext(UserContext);

  const { id } = props;

  const [override, toggleOverride] = useState(false);
  const [data, updateData] = useState([{}]);
  const [filtered, updateFiltered] = useState(null);
  const [filterState, updateFilteredState] = useState(null);
  const [message, showMessage] = useState(false);
  const [idFilter, updateIDFilter] = useState([{}]);
  const [exportFile, setExportFile] = useState(null);
  const [loading, toggleLoading] = useState(true);
  // Paginate
  const [total, updateTotal] = useState(0);
  const [paginated, updatePaginated] = useState([{}]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, updatePerPage] = useState(10);

  const updateReturn = useCallback(async () => {
    toggleLoading(true);
    showMessage(false);
    const { success, error } = await getLeads();
    // console.log(success);
    if (success) {
      if (id && !override) {
        const lead = success.data.filter((lead) => lead.confirmation === id);
        console.log(lead);
        success.data && updateData(success.data);
        success.message && showMessage(success.message);
        if (lead) {
          updateTotal(1);
          updateIDFilter(lead);
          paginateResults(lead);
        }
      } else {
        if (success.data) {
          updateTotal(success.total);
          updateData(success.data);
          paginateResults(success.data);
        }
        success.message && showMessage(success.message);
      }
    }
    setTimeout(() => {
      toggleLoading(false);
    }, 700);
    error && console.log(error);
  }, [id, override]);

  useEffect(() => {
    updateReturn();
  }, [updateReturn]);

  const showAll = () => {
    override ? paginateResults(idFilter) : paginateResults(data);
    toggleOverride(!override);
  };

  const exportData = async () => {
    const { success, error } = await getLeadsExport(filterState);
    if (success) {
      console.log(success);
      success.file && setExportFile(success.file);
    }
    error && console.log(error);
  };

  const paginateResults = (values = null, pageNumber = null, pages = null) => {
    let offset = 0;

    pageNumber
      ? (offset = (pageNumber - 1) * (pages ? pages : perPage))
      : (offset = (currentPage - 1) * (pages ? pages : perPage));

    values === null && (values = data);
    updateTotal(values.length);

    const pageData = values
      .slice(offset, offset + (pages ? pages : perPage))
      .map((v) => v);

    updatePaginated(pageData);
  };

  const handlePageChange = (pageNumber) => {
    console.log(filtered);
    console.log(data);

    filtered.length > 0
      ? paginateResults(filtered, pageNumber)
      : paginateResults(data, pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleFiltered = (data) => {
    updateFiltered(data);
    paginateResults(data);
  };

  return (
    <>
      <Nav />
      <div className="container-fluid flex-layout">
        <div className="container flex column">
          <Search
            source={data}
            user={user}
            clear={() => handleFiltered(null)}
            update={(e) => handleFiltered(e)}
            filterState={(e) => updateFilteredState(e)}
          />
        </div>
        <div className="container flex apart mxw-900 mt-5">
          <h1 className="title">
            Leads <span>({total})</span>
          </h1>
          <div>
            {id && (
              <Button
                className="btn btn-secondary"
                onClick={() => showAll()}
                text={!override ? "Show All" : "Show One"}
              />
            )}
            {user.role !== "member" && (
              <Button
                className="btn btn-warning"
                onClick={() => exportData()}
                text="Export"
              />
            )}
          </div>
        </div>
        <div className="container lead-wrapper">
          <Loading loading={loading} message="Getting leads">
            {message ? (
              <section className="mini p-4 text-center">
                <h2>{message}</h2>
              </section>
            ) : (
              <>
                {paginated &&
                  paginated.map((v) => <Lead key={v.confirmation} v={v} />)}

                {total > perPage && (
                  <Pagination
                    hideDisabled
                    hideNavigation
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={7}
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </>
            )}
          </Loading>
        </div>
      </div>
      <div id="exportFrame">
        {exportFile && (
          <iframe src={exportFile} title="Export" frameBorder="0"></iframe>
        )}
      </div>
    </>
  );
}
