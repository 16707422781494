import React from "react";
import { Nav } from "../components/nav";
import MZ from "../forms/mz/leads";
import SmartCity from "../forms/sc/leads";
import ClassicLocators from "../forms/cl/leads";
import NotFound from "./404";

const Submit = (props) => {
	switch (props.user.brand) {
		case process.env.REACT_APP_MOVE_EZ:
			return (
				<>
					<Nav />
					<MZ />
				</>
			);
		case process.env.REACT_APP_SMART_CITY:
			return (
				<>
					<Nav />
					<SmartCity />
				</>
			);
		case process.env.REACT_APP_CLASSIC_LOCATORS:
			return (
				<>
					<Nav />
					<ClassicLocators />
				</>
			);
		default:
			return <NotFound />;
	}
};

export default Submit;
